import React from "react"
import "./PricingTable.scss";

const PricingTable = () => (
  <div className="pricing-table">
  
  <div className="pricing-plan">
    <div className="plan-header">
      1 credit
    </div>
    <div className="plan-price"><span className="plan-price-amount"><span className="plan-price-currency">£</span>00.00</span>/credit</div>
    <div className="plan-items">
      <div className="plan-item">Pay as you go</div>
      <div className="plan-item">-</div>
    </div>
    <div className="plan-footer">
      <button className="button is-fullwidth" disabled="disabled">Choose</button>
    </div>
  </div>

  {/* <div class="is-divider-vertical" data-content="OR"></div> */}

  <div className="pricing-plan is-warning">
    <div className="plan-header">100 credits</div>
    <div className="plan-price"><span className="plan-price-amount"><span className="plan-price-currency">£</span>00.00</span></div>
    <div className="plan-items">
      <div className="plan-item">Auto re-buy</div>
      <div className="plan-item">£0.00 per credit</div>
    </div>
    <div className="plan-footer">
      <button className="button is-fullwidth">Choose</button>
    </div>
  </div>

  <div className="pricing-plan is-danger">
    <div className="plan-header">1000 credits</div>
    <div className="plan-price">
      <span className="plan-price-amount">
        <span className="plan-price-currency">£</span>00.00
      </span>
    </div>
    <div className="plan-items">
      <div className="plan-item">Auto re-buy</div>
      <div className="plan-item">£0.00 per credit</div>
    </div>
    <div className="plan-footer">
      <button className="button is-fullwidth">Choose</button>
    </div>
  </div>
</div>
)

export default PricingTable;