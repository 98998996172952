import React from "react"
import Layout from "../components/Layout/Layout"
import PricingTable from "../components/PricingTable/PricingTable"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import SEO from "../components/Seo/Seo"

const PricingPage = () => (
  <Layout header={true} footer={true}>
    <SEO title="Home" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column content">
            <h1>Pricing</h1>
            <p>
              Our simple pricing scheme allows you to start using our platform from just 00p per credit.  
            </p>
            <div className="tabs">
              <ul>
                <li className="is-active">
                  <a>Pay as you go</a>
                </li>
                <li>
                  <a>Monthly</a>
                </li>
                <li>
                  <a>Calculator</a>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
        <div className="columns">
          <div className="column content is-small">
          <ul>
              <li>1 credit = 1 hr/1 attendee (Webcam) </li>
              <li>2 credits = 1 hr/1 attendee (RTSP) </li>
            </ul>
            <PricingTable/>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PricingPage
